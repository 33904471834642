<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row :gutter="10">
                    <a-col :span="3">
                        <a-month-picker v-model="currentDate" style="width: 100%" :allowClear="false" placeholder="选择月份" @change="refresh"/>
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                                style="width: 100%"
                                allowClear
                                multiple
                                @change="handleCompanyChange"
                                :placeholder="l('Company')"
                                tree-default-expand-all
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="companyTreeList"
                                v-model="comPanyId"
                        />
                    </a-col>
<!--                    <a-col :span="4">-->
<!--                        <a-tree-select-->
<!--                                style="width: 100%"-->
<!--                                multiple-->
<!--                                allowClear-->
<!--                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--                                :tree-data="DepTreeData"-->
<!--                                :placeholder="l('Department')"-->
<!--                                tree-default-expand-all-->
<!--                                v-model="departmentId"-->
<!--                                @change="getData"-->
<!--                        >-->
<!--                        </a-tree-select>-->
<!--                    </a-col>-->
                    <a-col :span="17" style="text-align: left">
                        <a-divider type="vertical" v-if="isGranted('sl_month_diff_excel')" />
                        <a-button type="primary" @click="Export()" v-if="isGranted('sl_month_diff_excel')">
                            <a-icon type="download" />
                            <span>导出</span>
                        </a-button>
                    </a-col>
                </a-row>
                <a-row :gutter="[20,10]">
                    <a-col :span="10">
                        <a-table
                                :columns="columns"
                                :pagination="false"
                                :scroll="{ y: scroll_y,x:800}"
                                :rowKey="(data,index) => index"
                                :dataSource="tableData"
                        >
                        </a-table>
                        <a-pagination
                                class="pagination"
                                :total="totalItems"
                                v-model="pageNumber"
                                :showTotal="showTotalFun"
                                @change="onChangePage"
                                @showSizeChange="showSizeChange"
                        />
                    </a-col>
                    <a-col :span="14" style="overflow-x: auto">
                        <div id="report" :style="{'height':scroll_y+'px'}"></div>
                    </a-col>
                </a-row>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN";
    import moment from "moment"
    import {
        CompanyServiceProxy,
        DepartmentServiceProxy,
        SalaryReportServiceProxy
    } from "../../../../shared/service-proxies";
    import DepTreeUntils from "../../../organization/department/dep-tree-untils/dep-tree-untils";
    import * as echarts from 'echarts';
    import {fileDownloadService} from "../../../../shared/utils";

    export default {
        name: "salary-month-diff",
        mixins: [AppComponentBase],
        data() {
            return {
                zh_CN,
                //表格上方间隔
                defaultTop: -80,
                currentDate: undefined,
                companyTreeList: [], //公司
                comPanyId: undefined, //公司id
                departmentId: undefined,
                //部门树
                DepTreeData: [],
                DepTree: [],
                // 用户表格
                columns: [
                    {
                        title: '公司',
                        dataIndex: 'companyName',
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'companyName'},
                    },
                    {
                        title: '部门',
                        dataIndex: 'department',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'department'},
                    },
                    {
                        title: '上月实发',
                        dataIndex: 'lastMonthSalary',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'lastMonthSalary'},
                    },
                    {
                        title: '本月实发',
                        dataIndex: 'monthSalary',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'monthSalary'},
                    },
                    {
                        title: '差异',
                        dataIndex: 'salaryDiff',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'salaryDiff'},
                    }
                ],
                tableData: [],
                totalItems: 0, //总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ['10', '20', '30', '40'],
                request: {maxResultCount: 10, skipCount: 0, sorting: ''},
            }
        },
        created() {
            // 公司
            this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
            //部门
            this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
            this._salaryReportServiceProxy = new SalaryReportServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        mounted() {
            this.currentDate = moment();
            this.initCompany();
            this.refresh();
        },
        methods: {
            refresh() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            getData() {
                this.loading = true;
                this._salaryReportServiceProxy.getSalaryDiffByMonth(
                    this.comPanyId ? _.join(this.comPanyId, ",") : undefined,
                    this.departmentId ? _.join(this.departmentId, ",") : undefined,
                    this.currentDate.year(),
                    this.currentDate.month() + 1,
                    undefined,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.tableData = res.items;
                        this.totalItems = res.totalCount;
                        this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);

                        this.pagerange = [
                            (this.pageNumber - 1) * this.request.maxResultCount + 1,
                            this.pageNumber * this.request.maxResultCount,
                        ];
                        this.reportInit();
                    })
            },
            Export(){
                this.loading=true;
                this._salaryReportServiceProxy.getSalaryDiffByMonthToExcel(
                    this.comPanyId ? _.join(this.comPanyId, ",") : undefined,
                    this.departmentId ? _.join(this.departmentId, ",") : undefined,
                    this.currentDate.year(),
                    this.currentDate.month() + 1,
                    undefined,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(()=>{this.loading=false})
                .then(res=>{
                    this._fileDownloadService.downloadTempFile(res);
                })
            },
            /**
             * 格式化数据
             */
            reportDataInit() {
                let eData = {
                    xAxis: [],
                    lastMonthSalary: [],
                    monthSalary: [],
                    salaryDiff: []
                };
                this.tableData.forEach((item, index) => {
                    eData.xAxis.push(item.department);
                    eData.lastMonthSalary.push(item.lastMonthSalary);
                    eData.monthSalary.push(item.monthSalary);
                    eData.salaryDiff.push(item.salaryDiff);
                });
                return eData;
            },
            /**
             * Eachart dom渲染
             */
            reportInit() {
                let data = this.reportDataInit();
                let chartDom = document.getElementById('report');
                let myChart = echarts.init(chartDom);
                let option;
                let labelOption = {
                    show: true,
                    position: 'top',
                    distance: 15,
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: 10,
                    // color:"#252525"
                };

                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                    },
                    legend: {
                        data: ['上月实发', '本月实发', '差异']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: data.xAxis,
                            axisLabel:
                                {
                                    formatter: function (value) {
                                        let valueTxt = '';
                                        if (value.length > 5) {
                                            valueTxt = value.substring(0, 5) + '...';
                                        } else {
                                            valueTxt = value;
                                        }
                                        return valueTxt;
                                    },
                                    // color:"#252525",
                                    rotate:-40
                                },
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '上月实发',
                            type: 'bar',
                            label: labelOption,
                            data: data.lastMonthSalary
                        },
                        {
                            name: '本月实发',
                            type: 'bar',
                            label: labelOption,
                            data: data.monthSalary
                        },
                        {
                            name: '差异',
                            type: 'bar',
                            // label: labelOption,
                            data: data.salaryDiff.map((item) => {
                                return {
                                    value: item,
                                    label: {
                                        show: true,
                                        position: item >=0 ? 'top' : 'bottom',
                                        distance: 15,
                                        align: 'center',
                                        verticalAlign: 'middle',
                                        textStyle: {
                                            fontSize: 10,
                                            // color:"#252525"
                                        }
                                    }
                                }
                            })
                        }
                    ]
                };

                myChart.setOption(option);
                window.addEventListener('resize', () => {
                    myChart.resize();
                });

            },
            //初始化公司
            initCompany() {
                this._companyConfigServiceProxy
                    .getPaged(undefined, '', 100, 0)
                    .finally(() => {
                        // this.spinning = false;
                    })
                    .then((res) => {
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName,
                                };
                                this.companyTreeList.push(obj);
                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                        // if (this.companyTreeList.length > 0) {
                        //     this.handleCompanyChange(this.companyTreeList[0].key);
                        // }
                    });
            },
            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName,
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            //选择公司
            handleCompanyChange(value) {
                this.comPanyId = value;
                this.refresh();
                this.departmentId = undefined;
                // this.getDepTreeData();
            },
            /**
             * 获取部门
             */
            getDepTreeData() {
                this.DepTreeData = [];
                this._departmentServiceProxy
                    .getDepTreeData(this.comPanyId)
                    .then((res) => {
                        this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
                        this.DepTree.forEach((item) => {
                            if (!item.parentId) {
                                this.DepTreeData.push(item);
                            }
                        });
                        this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
                    })
                    .finally(() => {
                        // this.loading = false;
                    });
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    'GridFooterDisplayText',
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * 分页
             */
            onChangePage(page, pageSize) {
                this.selectedRowKeys = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.selectedRowKeys = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
                } else {
                    this.request.sorting = undefined;
                }
                this.refresh();
            },
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
